/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/docsify@4.13.1/lib/docsify.min.js
 * - /npm/docsify@4.13.1/lib/plugins/zoom-image.min.js
 * - /npm/docsify-tabs@1.6.3/dist/docsify-tabs.min.js
 * - /npm/docsify@4.13.1/lib/plugins/ga.min.js
 * - /npm/docsify-fontawesome@0.0.9/dist/docsify-fontawesome.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
